import Image from "next/image";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";

export default function TestimonialCarousel({
  card,
  numberOfCard = [],
  responsive,
}) {
  return (
    <div className="relative mt-[2.5rem] lg:mt-[4rem]">
      <div className="absolute -z-10 -right-8 -top-4 sm:-right-4 lg:-top-[3rem] lg:-right-2 min-[1800px]:right-[4rem] min-[1900px]:right-[9rem] w-[6.25rem] h-[4.375rem] lg:w-[9.625rem] lg:h-[6.75rem]">
        <Image
          src="/assets/testimonials/quotemarks-right-red.svg"
          fill
          alt="Quotemark Decoration"
          className="pointer-events-none"
        />
      </div>

      <Carousel
        swipeable={false}
        draggable={false}
        showDots={true}
        responsive={responsive}
        ssr={true}
        infinite={true}
        autoPlay={true}
        autoPlaySpeed={4000}
        keyBoardControl={true}
        transitionDuration={1000}
        arrows={false}
        containerClass="pt-[2rem] pb-[2rem] relative z-10 -mt-[3rem] max-w-[1440px] mx-auto"
        itemClass="px-[0.3rem] lg:px-[0.8rem]"
      >
        {numberOfCard.map((testimonial, index) => {
          return (
            <div className="h-full" key={index}>
              {card(testimonial)}
            </div>
          );
        })}
      </Carousel>
    </div>
  );
}
