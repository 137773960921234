export default function SeoContent() {
  return (
    <section className="text-grayD1 space-y-4">
      <h1 className="text-[1.25rem] lg:text-[1.5rem] font-bold leading-[190%]">
        Best Pet Shop in Bangladesh – Buy Premium Cat Food, Dog Products &
        Accessories with Fast Delivery!
      </h1>
      <p className="text-grayD2 leading-[162.5%] text-[0.938rem] lg:text-[1rem]">
        <span className="font-bold">Amarpet</span> is your trusted pet shop in
        Bangladesh, offering everything your pets need. Our selection includes
        high-quality cat food, dog food, rabbit food, bird food, and essential
        pet accessories. Whether you need nutritious meals for your cats and
        dogs or supplies for rabbits and birds,&nbsp;
        <span className="font-bold">Amarpet</span> has it all. Experience
        exceptional service and competitive prices at your reliable pet shop!
      </p>

      <h2 className="text-[1.125rem] lg:text-[1.25rem] font-semibold leading-[190%]">
        Cat Products
      </h2>
      <p className="text-grayD2 leading-[162.5%] text-[0.938rem] lg:text-[1rem] break-words">
        Discover our range of premium cat products for health and happiness. We
        provide nutritious cat food from trusted brands like&nbsp;
        <a
          href="https://amarpet.com/brands/smartheart"
          target="_blank"
          className="underline text-blue"
        >
          SmartHeart
        </a>
        ,&nbsp;
        <a
          href="https://amarpet.com/brands/whiskas"
          target="_blank"
          className="underline text-blue"
        >
          Whiskas
        </a>
        ,&nbsp;
        <a
          href="https://amarpet.com/brands/lara"
          target="_blank"
          className="underline text-blue"
        >
          Lara
        </a>
        ,&nbsp;
        <a
          href="https://amarpet.com/brands/bonacibo"
          target="_blank"
          className="underline text-blue"
        >
          Bonacibo
        </a>
        ,&nbsp;
        <a
          href="https://amarpet.com/brands/jungle"
          target="_blank"
          className="underline text-blue"
        >
          Jungle
        </a>
        ,&nbsp;
        <a
          href="https://amarpet.com/brands/haisenpet"
          target="_blank"
          className="underline text-blue"
        >
          Haisenpet
        </a>
        ,&nbsp;
        <a
          href="https://amarpet.com/product/felicia-low-grain-starter-care-kitten-chicken-2kg"
          target="_blank"
          className="underline text-blue"
        >
          Felicia
        </a>
        ,&nbsp;
        <a
          href="https://amarpet.com/brands/billi"
          target="_blank"
          className="underline text-blue"
        >
          Billi
        </a>
        ,&nbsp;
        <a
          href="https://amarpet.com/brands/paw-paw"
          target="_blank"
          className="underline text-blue"
        >
          Paw Paw
        </a>
        ,&nbsp;
        <a
          href="https://amarpet.com/brands/trendline"
          target="_blank"
          className="underline text-blue"
        >
          Trendline
        </a>
        , and more. <br />
        <br /> We also offer essential&nbsp;
        <a
          href="https://amarpet.com/category/cat-litter"
          target="_blank"
          className="underline text-blue"
        >
          cat litter
        </a>
        , including clumping, non-clumping, and biodegradable options. Our
        litter boxes come in various sizes and styles to suit your cat&apos;s
        needs, from covered boxes for privacy to open designs for easy access.
        <br />
        <br />
        Additionally, explore our selection of&nbsp;
        <a
          href="https://amarpet.com/category/cat-accessories"
          target="_blank"
          className="underline text-blue"
        >
          toys
        </a>
        , scratching posts,&nbsp;
        <a
          href="https://amarpet.com/category/clothing-beds-carrier"
          target="_blank"
          className="underline text-blue"
        >
          cat clothing, beds, carriers
        </a>
        , and grooming tools to keep your cat entertained and well-groomed.
      </p>

      <h2 className="text-[1.125rem] lg:text-[1.25rem] font-semibold leading-[190%]">
        Dog Products
      </h2>
      <p className="text-grayD2 leading-[162.5%] text-[0.938rem] lg:text-[1rem] break-words">
        Show your dog&apos;s love with our premium dog food and care products.
        Our selection features top brands like&nbsp;
        <a
          href="https://amarpet.com/brands/internutri"
          target="_blank"
          className="underline text-blue"
        >
          InterNutri
        </a>
        , Purina Pro Plan, and more for balanced nutrition. <br />
        <br /> We offer dog care products, including grooming supplies and
        health supplements. Our stylish accessories, such as collars, leashes,
        and toys, help keep your dog active and engaged.
      </p>

      <h2 className="text-[1.125rem] lg:text-[1.25rem] font-semibold leading-[190%]">
        Rabbit Products
      </h2>
      <p className="text-grayD2 leading-[162.5%] text-[0.938rem] lg:text-[1rem] break-words">
        Keep your rabbits nourished with our premium rabbit food. We provide
        healthy pellets and mixes tailored to their dietary needs. Brands
        like&nbsp;
        <a
          href="https://amarpet.com/brands/jungle"
          target="_blank"
          className="underline text-blue"
        >
          Jungle
        </a>
        &nbsp;and&nbsp;
        <a
          href="https://amarpet.com/brands/quik"
          target="_blank"
          className="underline text-blue"
        >
          Quik
        </a>
        &nbsp;ensure quality ingredients for your rabbits. <br />
        <br />
        Our rabbit food supports digestive health. We also offer bedding and
        accessories to create a comfortable living environment.
      </p>

      <h2 className="text-[1.125rem] lg:text-[1.25rem] font-semibold leading-[190%]">
        Bird Products
      </h2>
      <p className="text-grayD2 leading-[162.5%] text-[0.938rem] lg:text-[1rem] break-words">
        Feed your birds with our nutritious food, providing essential vitamins
        and minerals. We offer seeds, pellets, and mixes for various bird
        species, featuring brands like&nbsp;
        <a
          href="https://amarpet.com/brands/petslife"
          target="_blank"
          className="underline text-blue"
        >
          petslife
        </a>
        , Goldwings, and more. <br />
        <br />
        In addition, we provide bird accessories, including feeders, waterers,
        and toys for mental stimulation and activity.
      </p>

      <h2 className="text-[1.125rem] lg:text-[1.25rem] font-semibold leading-[190%]">
        Special Offers
      </h2>
      <p className="text-grayD2 leading-[162.5%] text-[0.938rem] lg:text-[1rem] break-words">
        <span className="font-semibold">
          5% Off on Your 1st Order via Our App
        </span>
        <br />
        <a
          href="https://onelink.to/2mpyxc"
          target="_blank"
          className="underline text-blue"
        >
          Download our app
        </a>
        &nbsp;for exclusive discounts on your first order. <br />
        <br />
        <span className="font-semibold">
          Up to 32% Off on Selected Cat & Dog Food!
        </span>
        <br />
        <a
          href="https://amarpet.com/offers"
          target="_blank"
          className="underline text-blue"
        >
          Save on premium pet food
        </a>
        &nbsp;with our limited-time discounts.
      </p>

      <h2 className="text-[1.125rem] lg:text-[1.25rem] font-semibold leading-[190%]">
        Why Choose Us?
      </h2>
      <p className="text-grayD2 leading-[162.5%] text-[0.938rem] lg:text-[1rem] break-words">
        At <span className="font-bold">Amarpet</span>, we prioritize premium
        quality products for your pets. Each item is carefully selected to meet
        high standards. Our commitment to quality has made us a trusted choice
        for pet owners in Bangladesh. We ensure fast and reliable delivery
        across the country. Enjoy top-quality products at affordable prices.
        Join thousands of satisfied customers who rely on us for their pet care
        essentials.
      </p>
    </section>
  );
}
