import apiClient from "@/services/api-client";
import successToast from "@/utils/toast";
import store from "@/store/index";
import en from "@/localization/en.json";
import { sendFPixelEvent, fbEvents } from "lib/fpixel";
import { errorLogger } from "@/utils/helpers/logger";

export const addToWishList = async (data) => {
  try {
    const res = await apiClient({
      method: "post",
      url: "/v1/wishlist",
      data: [data],
    });
    if (res.status === 200) {
      successToast(en["myWishlist.added_to_wishlist"]);
      sendFPixelEvent(fbEvents.addToWishList);

      store.dispatch(
        {
          type: "wishlist/updateWishlistItemCount",
          payload: res?.data?.data?.wishlist?.length,
        },
        { root: true }
      );

      return res.data;
    }
  } catch (error) {
    errorLogger.logApiError(error);
  }
};

export const fetchWishListInfo = async (headers = {}) => {
  try {
    const res = await apiClient({
      method: "POST",
      url: "/v1/wishlist",
      headers,
    });
    if (res.status === 200) {
      return res.data;
    }
  } catch (error) {
    errorLogger.logApiError(error);
  }
};

export const removeWishList = async (data) => {
  try {
    const res = await apiClient({
      method: "delete",
      url: "/v1/wishlist",
      data: data,
    });

    if (res.status === 200) {
      successToast(en["myWishlist.removed_from_wishlist"]);
      store.dispatch(
        {
          type: "wishlist/updateWishlistItemCount",
          payload: res?.data?.data?.wishlist?.length,
        },
        { root: true }
      );

      return res.data;
    }
  } catch (error) {
    errorLogger.logApiError(error);
  }
};
