import MultiCard from "@/components/carousel/multicard";
import { PropTypes } from "prop-types";
import BrandCard from "./card";

const responsive = {
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 7,
    slidesToSlide: 1,
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 4,
    slidesToSlide: 1,
  },
  mobile: {
    breakpoint: { max: 578, min: 0 },
    items: 2,
    slidesToSlide: 1,
  },
};

function BrandCardsHolder({ ourBrands }) {
  return (
    <div className="mb-[3rem]">
      <MultiCard
        card={BrandCard}
        numberOfCard={ourBrands}
        responsive={responsive}
      />
    </div>
  );
}

BrandCardsHolder.propTypes = {
  ourBrands: PropTypes.array,
};

export default BrandCardsHolder;
