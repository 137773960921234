import en from "@/localization/en.json";
import Image from "next/legacy/image";
import Link from "next/link";
import { useState } from "react";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";

function Banner({ sliderImages }) {
  const [transitionTime, setTransitionTime] = useState(500);

  const handleOnChange = (index) => {
    if (index === 0) {
      setTransitionTime(0);
    } else if (index === 1) {
      setTransitionTime(500);
    }
  };
  if (!sliderImages)
    return <h1 className="text-center">{en["home.no_image_found"]}</h1>;
  return (
    <div className="relative px-[.5rem] lg:px-[1.5rem] pt-[0.6rem] sm:pt-[1.5rem] w-full h-full">
      <Carousel
        className="banner-carousel"
        showThumbs={false}
        showStatus={false}
        transitionTime={transitionTime}
        interval={3000}
        onChange={handleOnChange}
        infiniteLoop={true}
        autoPlay
        renderArrowPrev={(onClickHandler, hasPrev, label) =>
          hasPrev && (
            <div>
              <button
                type="button"
                onClick={onClickHandler}
                title={label}
                className="hidden absolute z-10 top-[calc(50%_-_20px)] -left-[20px] cursor-pointer lg:block w-[3rem] h-[3rem] rounded-full bg-white"
              >
                <Image
                  src="/assets/icons/left-arrow.svg"
                  alt="visa"
                  width={9}
                  height={16}
                  className="mx-auto"
                />
              </button>
            </div>
          )
        }
        renderArrowNext={(onClickHandler, hasNext, label) =>
          hasNext && (
            <button
              type="button"
              onClick={onClickHandler}
              title={label}
              className="hidden absolute z-10 top-[calc(50%_-_20px)] -right-[20px] cursor-pointer lg:block w-[3rem] h-[3rem] rounded-full bg-white"
            >
              <Image
                src="/assets/icons/right-arrow.svg"
                alt="visa"
                width={9}
                height={16}
                className="mx-auto"
              />
            </button>
          )
        }
        renderIndicator={(onClickHandler, isSelected, index, label) => {
          if (isSelected) {
            return (
              <li
                className="rounded-full inline-block w-[4px] h-[4px] lg:w-[.62rem] lg:h-[.62rem] mr-[.5rem] bg-[#E91F63]"
                aria-label={`Selected: ${label} ${index + 1}`}
                title={`Selected: ${label} ${index + 1}`}
              />
            );
          }
          return (
            <li
              className="rounded-full inline-block bg-[#828588] w-[4px] h-[4px] lg:w-[.62rem] lg:h-[.62rem] mr-[.5rem]"
              onClick={onClickHandler}
              onKeyDown={onClickHandler}
              value={index}
              key={index}
              role="button"
              tabIndex={0}
              title={`${label} ${index + 1}`}
              aria-label={`${label} ${index + 1}`}
            />
          );
        }}
      >
        {sliderImages.map((bgImageUrl, index) => {
          return (
            <Link href={`${bgImageUrl.link}`} key={bgImageUrl.banner}>
              <div key={index} className="h-full">
                <Image
                  src={bgImageUrl.banner}
                  alt="image1"
                  width={4176}
                  height={1200}
                  layout="responsive"
                  priority
                  quality="40"
                />
              </div>
            </Link>
          );
        })}
      </Carousel>
    </div>
  );
}

export default Banner;
