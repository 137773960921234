import Link from "next/link";
import PropTypes from "prop-types";

import BannerBgImage from "../BannerBgImage";

function SingleBanner(props) {
  const { redirectUrl, bgImageUrl, className, imageOptions, alt } = props;

  return (
    <Link href={redirectUrl}>
      <div
        className="cursor-pointer"
        onClick={redirectUrl === "" ? (e) => e.preventDefault() : undefined}
      >
        <BannerBgImage
          bgImageUrl={bgImageUrl}
          className={className}
          imageOptions={imageOptions}
          alt={alt}
        />
      </div>
    </Link>
  );
}

SingleBanner.propTypes = {
  redirectUrl: PropTypes.string,
  bgImageUrl: PropTypes.string,
  className: PropTypes.string,
  imageOptions: PropTypes.object,
};

SingleBanner.defaultProps = {
  redirectUrl: "",
};

export default SingleBanner;
