import React from "react";
import BlogList from "@/components/sections/BlogList";
import PrimaryBtn from "@/components/button/primaryBtn";

import TopOne from "@/assets/lifestyle/top-1.svg";
import TopTwo from "@/assets/lifestyle/top-2.svg";
import Middle from "@/assets/lifestyle/middle.svg";

import BottomOne from "@/assets/lifestyle/bottom-1.svg";
import BottomTwo from "@/assets/lifestyle/bottom-2.svg";
import BottomThree from "@/assets/lifestyle/bottom-3.svg";
import Link from "next/link";

function Lifestyle({ blogList = [] }) {
  return (
    <section className="relative  mt-[3.5rem] lg:mt-[5rem] px-[.75rem] lg:px-[7.5rem] bg-[#F3F6F8] pt-[4rem] pb-[4.5rem]">
      <div className=" pointer-events-none">
        <TopOne className="absolute top-[0.625rem] left-[0.5rem] lg:top-[2.063rem] lg:left-[7.125rem] w-[4.25rem] h-[4.188rem] lg:w-[8.813rem] lg:h-[8.813rem] opacity-20" />

        <TopTwo className="absolute top-[1.375rem] right-[0.688rem] lg:top-[4.375rem] lg:right-[4.125rem] w-[2.563rem] h-[2.625rem] lg:w-[5.063rem] lg:h-[5.125rem] opacity-20" />

        <Middle className="absolute top-[44%] right-[1.438rem] opacity-20 w-[3.75rem] h-[3.75rem] hidden lg:block" />

        <BottomOne className="absolute bottom-[5.625rem] left-0 opacity-60 w-[4rem] h-[6.563rem] hidden lg:block" />

        <BottomTwo className="absolute bottom-[9.375rem] left-[70%] lg:bottom-[5.438rem] lg:left-[30%] opacity-40 w-[1.875rem] h-[2rem]  lg:w-[3.688rem] lg:h-[3.938rem]" />

        <BottomThree className="absolute bottom-[7.813rem] right-0 opacity-40 w-[3.625rem] h-[5rem] hidden lg:block" />
      </div>

      <div className="space-y-[18px] mb-[52px]">
        <h2 className="text-[32px] font-bold text-center ">Pets Lifestyle</h2>
        <p className="text-[#0A101A] text-lg font-normal text-center">
          Track and explore your amazing pets lifestyle with amarpet
        </p>
      </div>

      <BlogList blogData={blogList} />

      <div className="mt-[44px] flex justify-center">
        <Link href="/blogs">
          <PrimaryBtn btnText=" View All >" className="w-[120px] h-[48px]" />
        </Link>
      </div>
    </section>
  );
}

export default Lifestyle;
