import Image from "next/image";
import Link from "next/link";
import { PropTypes } from "prop-types";

function HomeCategoryCard(props) {
  const {
    categoryName,
    categorySlug = "#",
    imageHolderClassName = "",
    imageClassName = "",
    imageUrl,
    textHolderClassName = "",
  } = props;

  return (
    <Link
      href={{ pathname: `/category/[slug]`, query: { slug: categorySlug } }}
    >
      <div className="cursor-pointer inline-block">
        <div className={`flex rounded-[50%] ${imageHolderClassName}`}>
          <div className={`relative ${imageClassName}`}>
            <Image
              src={imageUrl}
              alt={categoryName}
              className="rounded-[50%] object-contain"
              fill
              sizes="33vw"
              priority
            />
          </div>
        </div>

        <h4
          className={`text-center text-grayD1 text-heading5 font-medium mt-[1.25rem] ${textHolderClassName} line-clamp-2`}
        >
          {categoryName}
        </h4>
      </div>
    </Link>
  );
}

export default HomeCategoryCard;

HomeCategoryCard.propTypes = {
  categoryName: PropTypes.string,
  categorySlug: PropTypes.string,
  imageHolderClassName: PropTypes.string,
  imageClassName: PropTypes.string,
  imageUrl: PropTypes.string,
  textHolderClassName: PropTypes.string,
};
