import React from "react";
import Image from "next/image";
import Link from "next/link";

//`/search?brands=${brand.link}

function BrandCard(brand) {
  if (brand)
    return (
      <Link
        href={{
          pathname: `/brands/[brand_slug]`,
          query: { brand_slug: brand.link },
        }}
      >
        <div className="w-full aspect-square">
          <div className="grayscale hover:grayscale-0 cursor-pointer  w-full h-full">
            <Image src={brand.logo} alt="brand icon" width={400} height={400} />
          </div>
        </div>
      </Link>
    );
}

export default BrandCard;
