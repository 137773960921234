import PropTypes from "prop-types";

import { StarFillIcon, StarHalfFillIcon, StarIcon } from "../Icon";

function Rating({ ratingNumber, className, starClassName }) {
  const MAX_RATING = 5;
  const ratingScore = Math.min(ratingNumber, MAX_RATING);

  let isFloatNumber = ratingScore % 1 !== 0;
  let roundedStarRating = Math.floor(ratingScore);
  let emptyStarNumber =
    MAX_RATING - roundedStarRating - (isFloatNumber ? 1 : 0);
  let filledStarArr = Array.from(Array(roundedStarRating).keys());
  let EmptyStarArr = Array.from(Array(emptyStarNumber).keys());

  return (
    <div className={`${className} flex items-center space-x-[0.281rem]`}>
      {filledStarArr.map((star) => (
        <StarFillIcon key={star} className={starClassName} />
      ))}
      {isFloatNumber && <StarHalfFillIcon className={starClassName} />}
      {EmptyStarArr.map((star) => (
        <StarIcon key={star} className={starClassName} />
      ))}
    </div>
  );
}

Rating.propTypes = {
  ratingNumber: PropTypes.number,
  className: PropTypes.string,
  starClassName: PropTypes.string,
};

export default Rating;
