import en from "@/localization/en.json";

export default [
  {
    id: 1,
    name: en["home.vet_care"],
    imageUrl: "/assets/pet-care/putting-funnel.png",
  },
  {
    id: 2,
    name: en["home.vaccinations"],
    imageUrl: "/assets/pet-care/animal.png",
  },
  {
    id: 3,
    name: en["home.telemedicine"],
    imageUrl:
      "/assets/pet-care/asian-veterinarian-girl-talk-to-pet-owner-on-onlin.png",
  },
  {
    id: 4,
    name: en["home.nutritional"],
    imageUrl:
      "/assets/pet-care/image-of-female-pet-hairdresser-grooming-pomerania.png",
  },
  {
    id: 5,
    name: "Nutritional",
    imageUrl:
      "/assets/pet-care/nominated-big-lazy-overweight-cat-lying-wit.png",
  },
];
