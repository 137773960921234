import React from "react";

function StockOutBadge() {
  return (
    <div className="absolute bg-[#E6457A] rounded-[8px] opacity-100">
      <p className="text-[#FFFFFF] px-[20px] py-[10px] z-50">Stock Out</p>
    </div>
  );
}

export default StockOutBadge;
