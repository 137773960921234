import _ from "lodash";

export const getWishlistUpdatedProducts = (wishlist, products) => {
  wishlist.forEach((wishlistItem) => {
    products.forEach((product) => {
      if (wishlistItem.slug === product.slug) {
        product.default_variation.in_wishList = true;
      }
    });
  });

  return _.cloneDeep(products);
};
