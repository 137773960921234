import React from "react";
import Image from "next/image";
import Styles from "./index.module.css";
import en from "@/localization/en.json";

function PetCareCard(service) {
  return (
    <div
      className={`relative w-full max-w-[13.6rem] h-[14.375rem] bg-white rounded-[8px] md:rounded-[12px] cursor-pointer p-[.75rem] ${Styles.petCareCardHolder}`}
    >
      <div className="flex justify-center">
        <Image
          src={service.imageUrl}
          width={192}
          height={156}
          alt="pet-care-card"
        />
      </div>
      <p className="text-darkPrimary text-heading4 text-center mt-[1rem] mb-[1.25rem]">
        {service.name}
      </p>

      <div
        className={`${Styles.overlay} rounded-[8px] md:rounded-[12px] flex justify-center items-center`}
      >
        <h5 className="text-heading5 text-white font-medium">
          {en["common.upcoming"]}
        </h5>
        {/* <Image
          src="/assets/icons/triangle-right.svg"
          width={10}
          height={12}
          alt="play-btn"
        /> */}
      </div>
    </div>
  );
}

export default PetCareCard;
