export const testimonials = [
  {
    comment:
      "I have been purchasing from Amarpet for quite a long time and now I have got the membership card as well! Totally satisfied with their quality products and fast delivery as my cat can enjoy their treats!😇 Totally recommended!💯",
    author_name: "Patrishia Karmaker",
    className: "",
    source: "facebook",
    author_picture: "/assets/testimonials/patrishia.png",
    rating: 5,
  },
  {
    comment:
      "I recently purchased from Amarpet.com, and I am thoroughly impressed! Highly recommend Amarpet.com for all pet owners!",
    author_name: "Sunny Haque",
    className: "",
    source: "facebook",
    author_picture: "/assets/testimonials/sunny.png",
    rating: 5,
  },
  {
    comment:
      "I have been buying products for my cats for awhile now from this page and I have to say that this page is definitely the best one to purchase anything for your pets.",
    author_name: "Zasia Tashnuva",
    className: "",
    source: "facebook",
    author_picture: "/assets/testimonials/zasia.png",
    rating: 5,
  },
  {
    comment:
      "I recently visited AmarPet and I am absolutely delighted with my experience! I was greeted by friendly and knowledgeable staff who were more than happy to help me find the perfect food and accessories for my furry friends. However, the variety of product available is incredible, catering to all types of pets with different dietary needs.",
    author_name: "Nadim Chowdhury",
    className: "",
    source: "facebook",
    author_picture: "/assets/testimonials/nadim.png",
    rating: 5,
  },
];
