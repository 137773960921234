import Image from "next/image";
import PropTypes from "prop-types";

function BannerBgImage({
  bgImageUrl,
  className,
  imageOptions,
  alt = "banner image",
}) {
  return (
    <div className={`relative ${className}`}>
      <Image
        src={bgImageUrl}
        alt={alt}
        priority={imageOptions.priority}
        quality={imageOptions.quality}
        className={`${imageOptions.className} ${imageOptions.style} object-cover object-top`}
        fill
        sizes="100vw"
      />
    </div>
  );
}

BannerBgImage.propTypes = {
  bgImageUrl: PropTypes.string.isRequired,
  className: PropTypes.string,
  imageOptions: PropTypes.object,
};

BannerBgImage.defaultProps = {
  bgImageUrl: "/assets/banner/product-banner.png",
  className: "bg-[#B6E3E5] aspect-[99/19]",
  imageOptions: {
    priority: false,
    quality: "50",
  },
};

export default BannerBgImage;
