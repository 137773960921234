import Image from "next/legacy/image";
import Link from "next/link";
import { LeftArrowIcon } from "../../Icon";

function BlogCard({ blogInfo, blogHrefPrefix = "blogs" }) {
  const href = `/${blogHrefPrefix}/${blogInfo?.slug}`;

  return (
    <Link href={href}>
      <div className="w-full cursor-pointer">
        <Image
          height={520}
          width={768}
          src={blogInfo?.thumbnail}
          className="rounded-xl"
          alt={blogInfo?.title}
          sizes="(max-width: 768px) 100vw, (max-width: 1200px) 50vw, 33vw"
        />
        <div className="pt-8 pb-6">
          <p className="text-grayD4 mb-[1.125rem]">
            {blogInfo?.published_date}
          </p>
          <h3 className="font-medium text-grayD2 text-[1.375rem] mb-2 line-clamp-2">
            {blogInfo?.title}
          </h3>
          <p className="text-grayD3 mb-8 line-clamp-2">
            {blogInfo?.short_description}
          </p>

          <p className="text-darkPrimary hover:underline flex items-center space-x-3">
            <span>READ MORE</span>
            <LeftArrowIcon />
          </p>
        </div>
      </div>
    </Link>
  );
}

export default BlogCard;
