import MultiCard from "@/components/carousel/multicard";
import React from "react";
import PetCareCard from "./card";
import petCare from "@/utils/data/petCare";
import en from "@/localization/en.json";

const responsive = {
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 5,
    slidesToSlide: 1,
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 2,
    slidesToSlide: 1,
  },
  mobile: {
    breakpoint: { max: 578, min: 0 },
    items: 2,
    slidesToSlide: 1,
  },
};

function PetCareHolder() {
  return (
    <div
      className="w-full aspect-[720/307] bg-[#F3F6F8] bg-cover"
      style={{ backgroundImage: `url(/assets/banner/pet-care.png)` }}
    >
      <div className="py-[3.5rem] md:py-[6.25rem]">
        <div className="">
          <h2 className="text-display2 text-grayD1 text-center">
            {en["home.care_your_pets"]}
          </h2>
          <p className="text-body1 text-grayD1 opacity-[.6] font-normal text-center mt-[1.25rem]">
            {en["home.loving_pets"]}
          </p>
        </div>

        <div className="mt-[2.75rem] lg:mt-[3.875rem] mb-[3.5rem] lg:mb-[6.87rem] mx-[1.56rem] lg:mx-[3.875rem]">
          <MultiCard
            card={PetCareCard}
            numberOfCard={petCare}
            responsive={responsive}
          />
        </div>
      </div>
    </div>
  );
}

export default PetCareHolder;
