import BlogCard from "@/components/data-display/card/BlogCard";
import React from "react";

function BlogList({ blogData, blogHrefPrefix }) {
  return (
    <div className="grid md:grid-col-2 lg:grid-cols-3 gap-x-6 gap-y-12">
      {blogData.map((data) => (
        <BlogCard
          key={data.slug}
          blogInfo={data}
          blogHrefPrefix={blogHrefPrefix}
        />
      ))}
    </div>
  );
}

export default BlogList;
