import TestimonialCarousel from "@/components/carousel/Testimonial";
import TestimonialCard from "./card";

const responsive = {
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 3,
    slidesToSlide: 3,
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 2,
    slidesToSlide: 2,
  },
  mobile: {
    breakpoint: { max: 578, min: 0 },
    items: 1,
    slidesToSlide: 1,
  },
};

export default function Testimonials({ testimonials }) {
  return (
    <section className="text-grayD1">
      <div className="flex justify-between">
        <h2 className="font-semibold lg:text-[1.75rem]">
          Lovely Testimonial from Happy Customer
        </h2>
      </div>

      <TestimonialCarousel
        card={TestimonialCard}
        numberOfCard={testimonials}
        responsive={responsive}
      />
    </section>
  );
}
