import { QuotemarksLeftIcon } from "@/components/data-display/Icon";
import Rating from "@/components/data-display/Rating";
import Image from "next/image";
import { testimonialIconMap } from "../icons";

export default function TestimonialCard({
  comment,
  author_name,
  className,
  source,
  rating,
  author_picture,
}) {
  const SourceLogo = testimonialIconMap[source];

  return (
    <div
      className={`flex h-full flex-col justify-between rounded-xl p-[1rem] md:p-[1.5rem] bg-white z-10 shadow-[0px_0px_6px_0px_rgba(0,0,0,0.05)] md:shadow-[0px_0px_16px_2px_rgba(0,0,0,0.05)] ${className}`}
    >
      <div>
        <div className="mb-[1rem] md:mb-[1.375rem]">
          <QuotemarksLeftIcon />
        </div>
        <p
          title={comment}
          className="mb-[1.25rem] text-[0.938rem] line-clamp-5 lg:mb-[1.75rem] lg:text-[1rem] "
        >
          {comment}
        </p>
      </div>

      <div className="flex items-center justify-between text-[0.875rem] md:text-[1rem] ">
        <div className="flex items-center gap-[1.25rem]">
          {author_picture && (
            <div className="relative min-h-[3.5rem] min-w-[3.5rem]">
              <Image
                fill
                alt={author_name}
                src={author_picture}
                className="rounded-full object-cover"
              />
            </div>
          )}

          <div className="flex flex-col gap-[9px]">
            <p className="font-semibold text-[0.938rem] lg:text-[1rem]">
              {author_name}
            </p>
            <Rating ratingNumber={rating} />
          </div>
        </div>
        <SourceLogo className="w-[2rem] h-[2rem] lg:w-[2.5rem] lg:h-[2.5rem]" />
      </div>
    </div>
  );
}
