import React from "react";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";

function MultiCard({ card, numberOfCard = [], responsive }) {
  return (
    <Carousel
      swipeable={false}
      draggable={false}
      showDots={false}
      responsive={responsive}
      ssr={true}
      infinite={true}
      autoPlay={true}
      autoPlaySpeed={1500}
      keyBoardControl={true}
      transitionDuration={900}
      arrows={false}
      containerClass="carousel-container"
      removeArrowOnDeviceType={["tablet", "mobile"]}
      itemClass="carousel-item-padding-40-px"
      sliderClass=""
    >
      {numberOfCard.map((service, index) => {
        return (
          <div key={index} className="flex justify-center p-2">
            {card(service)}
          </div>
        );
      })}
    </Carousel>
  );
}

export default MultiCard;
